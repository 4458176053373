import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./pop-up");
  require("./simple-lightbox");
  require("./columns-height");

  $(".gallery, .lightbox").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }

  $('.house__slider').slick({
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    arrows: false
  });
  
  $('.level__slider').slick({
    speed: 500,
    fade: true,
    cssEase: 'linear',
    prevArrow: '<button class="slider-arrow slider-arrow_prev"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button class="slider-arrow slider-arrow_next"><i class="fas fa-chevron-right"></i></button>',
  });

  $('.interior__slider').slick({
    speed: 500,
    fade: true,
    cssEase: 'linear',
    prevArrow: '<button class="slider-arrow slider-arrow_prev"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button class="slider-arrow slider-arrow_next"><i class="fas fa-chevron-right"></i></button>',
  });

  $('.to-top').click(function() {
      $("html, body").animate({ scrollTop: "0" });
  });
});
