const lCol = $('.architecture__left').outerHeight();
const rCol = $('.architecture__right').outerHeight();
const borderImgRight = $('.border-img__right').outerHeight();
const logoTitle = $('.logo-title').outerHeight();
let archButton = $('.architecture__button');
x = lCol - borderImgRight - logoTitle
archButton.css('height', x)
console.log(lCol)
$('.architecture__right').css('min-height', lCol)
$('.architecture__left').css('height', borderImgRight + logoTitle + archButton.outerHeight())

console.log(rCol - borderImgRight - logoTitle);

